@media print {
  .noprint {
    display: none;
  }
}

.content__inner:not(.content__inner--sm) {
  max-width: 1600px;
}

//@custom - Inline Ul's
.inline-ul {
  display: -webkit-box;
  display: -moz-box;
}

//@custom Misc
.ma-backdrop-side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: $sidebar-z-index + 1;
  background: #000;
  opacity: .5 !important;
}

// @custom box-shadow for slide panel cards
.box-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12)
}

// @custom Scrollbar
.scroll-wrapper {
  & > .scroll-content {
    box-sizing: border-box !important;
  }
}

// @custom Profile
.profile-img-in-text {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
}

.profile__img {
  img {
    max-width: 170px;
  }
}

// @custom Listview (_listview.scss)
.listview__item {
  padding: 15px $card-spacer-x;
}

.listview__img {
  margin: -0.1rem 1.2rem 0 -0.2rem;
}

.listview__heading {
  font-size: 1.03rem;

  & > small {
    font-size: 0.78rem;
  }
}

.listview__attrs-sm {
  flex: 1 100%;
  margin-top: 0.5rem;

  & > span {
    padding: 0.4rem 0.7rem 0.4rem;
    border: 1px solid lighten($grey, 30%);
    display: inline-block;
    line-height: 100%;
    font-size: 0.87rem;
    margin: 0.2rem 0.25rem 0.055rem 0;
    background-color: $white;
  }
}

.listview__actions {
  position: absolute;
  right: 2rem;
  align-self: flex-end;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;

  }
}

/**
* Buttons
 */
.btn--action {
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 0;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  @include btn-raised();
  position: unset; // ovveriding original _buttons.scss
  bottom: unset; // ovveriding original _buttons.scss
  right: unset; // ovveriding original _buttons.scss

  &,
  &:hover,
  &:focus {
    color: $white;

  }
}

.btn--action-sm {
  z-index: 2;
  height: 37px;
  width: 37px;
  padding: 0;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  @include btn-raised();
  margin: 0 auto;

  &,
  &:hover,
  &:focus {
    color: $white;

  }
}

.btn--fixed {
  position: fixed !important;
  bottom: 30px;
  right: 30px;
}

/**
* Accordion for Cards inside
*
*/

.accordion {
  .card {
    box-shadow: none;
    margin: 0;
  }

  .card-title {
    padding: 0.9rem 0;
    box-shadow: 0 1px 0 0 #f7f7f7;
    @include transition(box-shadow 300ms);
    font-size: 1rem;
    font-weight: 500;
    display: block;

    & > a {
      color: $headings-color;
    }
  }

  .card-body {
    padding: 0.3rem 0 0.1rem 0;
  }
}

/**
* Form Group (form-group) label
 */
.form-group {
  label {
    font-size: .875rem;
  }
}