/* The heart of the matter */
.kanban-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  background: url('/img/timeline.jpg') no-repeat;
  background-size: cover;

  & .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-12 {
    display: inline-block;
    float: none;
    margin:0;
    white-space: normal;
    /*max-height: 400px;*/
    vertical-align: top;
    padding: 0;
    opacity: 0.99;
    min-width: 300px;

    @media (max-width: 450px) {
      margin-left: 0;
    }
  }

  & .fullscreen {
    z-index: 999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
}

/*.h-inline > .col-sm-2:hover, .h-inline > .col-sm-3:hover, .h-inline > .col-sm-4:hover, .h-inline > .col-sm-5:hover, .h-inline > .col-sm-12:hover {
  !*transition: opacity 0.7s;*!
  opacity: 1;
}*/



/*
.fullscreen .col-sm-2, .fullscreen .col-sm-3, .fullscreen .col-sm-4, .fullscreen .col-sm-5{
  height: 80%;
}*/
