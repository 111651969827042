// @custom
.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  background-color: #eceff1;
  z-index: $header-z-index + 1;
  box-shadow: -5px 0 10px rgba($black, 0.08);
  @include transition(transform 300ms, opacity 300ms);

  @media(min-width: 451px) {
    width: 80%
  }

  @media(max-width: 450px) {
    width: 100%
  }

  &:not(.toggled) {
    transform: translate3d((100%), 0, 0);
    opacity: 0;
  }

  &.toggled {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}