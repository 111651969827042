ul.jqtree-tree {
  border-top: 1px solid $treeview-item-border-color;

  li {
    &.jqtree-selected {
      & > .jqtree-element {
        &, &:hover {
          background: $treeview-item-active-bg;
          text-shadow: none;
        }
      }
    }

    &:not(.jqtree-selected) {
      & > .jqtree-element {

        &:hover {
          background: $treeview-item-hover-bg;
        }
      }
    }

    &.jqtree-folder {
      margin-bottom: 0;

      &:not(.jqtree-closed) {
        & + li.jqtree_common {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            width: 100%;
            background-color: $treeview-item-border-color;
            height: 1px;
          }
        }
      }

      &.jqtree-closed {
        margin: 0;
      }
    }

    &.jqtree-ghost {
      span.jqtree-line {
        background-color: $blue;
      }

      span.jqtree-circle {
        border-color: $blue;
      }
    }
  }

  .jqtree-moving > .jqtree-element .jqtree-title {
    outline: none;
  }

  span.jqtree-border {
    border-radius: 0;
    border-color: $blue;
  }

  .jqtree-toggler {
    position: absolute;
    height: 18px;
    width: 18px;
    background: $white;
    border: 1px solid darken($treeview-item-border-color, 5%);
    color: $headings-color;
    border-radius: 50%;
    padding: 0 0 0 1px;
    top: 12px;
    left: -10px;
    line-height: 17px;
    font-size: 1rem;
    text-align: center;
  }

  .jqtree-element {
    position: relative;
    padding: 10px 20px;
    border: 1px solid $treeview-item-border-color;
    border-top: 0;
    margin-bottom: 0;
  }

  .jqtree-title {
    color: $headings-color;
    margin-left: 0;
  }

  ul.jqtree_common {
    margin-left: 22px;
    padding-left: 10px;
  }
}