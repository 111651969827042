@mixin btn-raised {
  box-shadow: 0 4px 3px -2px rgba($black, 0.15), 0 2px 2px 0 rgba($black, 0.04), 0 1px 5px 0 rgba($black, 0.02) !important;

  &:hover {
    box-shadow: 0 2px 4px -1px rgba($black, 0.15), 0 4px 5px 0 rgba($black, 0.14), 0 1px 10px 0 rgba($black, 0.12) !important;
  }

  &:active {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 10px 2px rgba(0,0,0,.12) !important;
  }
}

.btn {
  transition: box-shadow 300ms, background-color 300ms, border-color 300ms;

  &:not([class*="btn-outline-"]) {
    border-color: transparent !important;
  }
}

.btn--raised {
  @include btn-raised();
}

.btn--icon {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  padding: 0;
  font-size: 1.2rem;
  text-align: center;
}

.btn--icon-text {
  & > .zmdi {
    font-size: 1.15rem;
    margin: -1px 5px 0 0;
    vertical-align: middle;
  }
}

.btn--action {
  z-index: 2;
  height: 50px;
  width: 50px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
  @include btn-raised();
  position: fixed;
  bottom: 30px;
  right: 30px;

  &,
  &:hover,
  &:focus {
    color: $white;

  }
}

.btn-group-justified {
  display: flex;
  width: 100%;
  .btn,
  .btn-group {
    flex: 1;
    .btn {
      width: 100%;
    }
  }
}

[data-toggle="buttons"]:not(.btn-group--colors) {
  & > .btn {
    background-color: $gray-100;
    cursor: pointer;
    box-shadow: none;
    border: 0;
    margin: 0;

    &:not(.active) {
      color: $body-color;
    }

    &.active {
      background-color: $light-blue;
      color: $white;
    }
  }
}

.btn-group--colors {
  & > .btn {
    box-shadow: none;
    border-radius: 50% !important;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-bottom: 3px;
    position: relative;

    &:not([class*="bg-"]) {
      border-color: $gray-100 !important;
    }

    &:before {
      @include font-icon('\f26b', 16px);
      @include transition(transform 200ms, opacity 200ms);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 28px;
      padding-right: 3px;
      color: $white;
      font-style: italic;
      transform: scale(0);
      opacity: 0;
    }

    &.btn:not([class*="bg-"]) {
      border: 1px solid darken($input-border-color, 5%);

      &:before {
        color: $body-color;
      }
    }

    &.active {
      &:before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}